import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";
import {
  OrderStatusIdEnum,
  RolesEnum,
  SortDirections,
  StatusEnum,
  ThemeColorsEnum,
  WorkOrderColumnsEnum,
} from "../constants/enums";

import { AppRoutes } from "../constants/appRoutes";
import { Badge } from "react-bootstrap";
import BranchPickerModal from "../components/modals/custom/BranchPickerModal";
import ChatIcon from "@mui/icons-material/Chat";
import CircleButton from "../components/buttons/CircleButton";
import CompleteWorkOrderModal from "../components/modals/custom/CompleteWorkOrderModal";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import UserPickerModal from "../components/modals/custom/UserPickerModal";
import { useContext } from "react";
import useCurrentUserInfo from "./useCurrentUserInfo";
import { useNavigate } from "react-router-dom";

const useWorkOrdersDataTable = (
  directDrops,
  onDeleted = () => {},
  onSaved = () => {},
  onMoved = () => {}
) => {
  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();

  const { setLoading } = useContext(LoadingContext);
  const { branchSettings, currentUser, currentClient } =
    useContext(AuthContext);
  const { showConfirmation, showModal } = useContext(ModalContext);

  const assignOrder = async (userId, workOrder) => {
    workOrder = {
      ...workOrder,
      assignedToUserId: userId,
    };

    setLoading(true);
    const response = await Services.api.workOrders.save(workOrder);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not assign the order",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order assigned", ThemeColorsEnum.success);
  };

  const completeOrder = async (workOrder) => {
    setLoading(true);
    const response = await Services.api.workOrders.complete(workOrder);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not complete the order",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Completed", ThemeColorsEnum.success);
    onSaved(response?.data);
  };

  const handleAssign = async (workOrder, event) => {
    showModal(
      "Pick a driver",
      null,
      null,
      <UserPickerModal
        initialText={workOrder?.assignedToName}
        onSave={assignOrder}
        role={RolesEnum.driver}
        selectedValue={workOrder?.assignedToUserId}
        workOrder={workOrder}
      />
    );
  };

  const handleComplete = async (workOrder) => {
    showModal(
      "Complete dispatch",
      null,
      null,
      <CompleteWorkOrderModal onSave={completeOrder} workOrder={workOrder} />
    );
  };

  const handleDelete = async (workOrder) => {
    setLoading(true);
    const response = await Services.api.workOrders.delete(workOrder.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the order",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Order deleted", ThemeColorsEnum.success);
    onDeleted(workOrder);
  };

  const handleMove = (workOrder) => {
    showModal(
      "Pick a branch",
      null,
      null,
      <BranchPickerModal
        branches={currentUser?.clients.filter(
          (x) => x.id !== currentClient?.id
        )}
        onSave={(branchId) => moveOrder(workOrder, branchId)}
      />
    );
  };

  const handleShowNotes = (workOrder, event) => {
    event.stopPropagation();
    event.preventDefault();
    showConfirmation("Notes", workOrder.notes, [
      {
        color: ThemeColorsEnum.quaternary,
        onClick: () => {},
        title: "OK",
      },
    ]);
  };

  const handleStart = async (workOrder, event) => {
    event.stopPropagation();

    showConfirmation(
      "Confirmation",
      "Do you really want to start this order?",
      [
        {
          color: ThemeColorsEnum.quaternary,
          title: "No",
        },
        {
          color: ThemeColorsEnum.danger,
          onClick: async () => await startOrder(workOrder),
          title: "Yes",
        },
      ]
    );
  };

  const handleUndoStart = async (workOrder) => {
    showConfirmation("Confirmation", "Do you really want to undo this?", [
      {
        color: ThemeColorsEnum.quaternary,
        title: "No",
      },
      {
        color: ThemeColorsEnum.danger,
        onClick: async () => await undoStart(workOrder),
        title: "Yes",
      },
    ]);
  };

  const moveOrder = async (workOrder, branchId) => {
    setLoading(true);
    const response = await Services.api.workOrders.move(
      workOrder?.id,
      branchId
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not move the order",
        ThemeColorsEnum.error
      );

    onMoved(response?.data);
    Services.utility.toast("Order moved", ThemeColorsEnum.success);
  };

  const startOrder = async (workOrder) => {
    setLoading(true);
    const response = await Services.api.workOrders.start(workOrder?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not start the order",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order started", ThemeColorsEnum.success);
  };

  const undoStart = async (workOrder) => {
    setLoading(true);
    const response = await Services.api.workOrders.undoStart(workOrder?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not undo this operation",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order reset", ThemeColorsEnum.success);
  };

  const menuItems = (row) => [
    // Start
    ...(row.orderStatusId === OrderStatusIdEnum.new
      ? [
          {
            onClick: (event) => handleStart(row, event),
            title: "Start",
          },
        ]
      : []),
    // Complete: if status is inProgress
    ...(row.orderStatusId === OrderStatusIdEnum.inProgress
      ? [
          {
            onClick: () => handleUndoStart(row),
            title: "Undo Start",
          },
          {
            onClick: () => handleComplete(row),
            title: "Complete",
          },
        ]
      : []),
    // Assign: if unassigned
    ...(row.status === StatusEnum.active &&
    (userInfo?.isAdmin ||
      (!row.isDriverLocked && userInfo?.canAssignDeliveryOrders))
      ? [
          {
            onClick: (event) => handleAssign(row, event),
            title: "Assign",
          },
        ]
      : []),
    // Edit
    // ...(row.status === StatusEnum.active && userInfo?.canEditDeliveryOrders
    ...(userInfo?.canEditDeliveryOrders
      ? [
          {
            // href: AppRoutes.products_edit.replace(":id", row.id),
            onClick: () =>
              navigate(
                (row.isDirectDrop
                  ? AppRoutes.directDrops_edit
                  : AppRoutes.deliveryOrders_edit
                ).replace(":id", row.id),
                {
                  state: { workOrder: row },
                }
              ),
            title: "Edit",
          },
        ]
      : []),
    // Duplicate
    ...(userInfo?.canCreateDeliveryOrders
      ? [
          {
            onClick: () => {
              navigate(
                row.isDirectDrop
                  ? AppRoutes.directDrops_create
                  : AppRoutes.deliveryOrders_create,
                {
                  state: {
                    workOrder: {
                      ...row,
                      id: 0,
                      dueDate: null,
                      orderStatusId: OrderStatusIdEnum.new,
                      status: StatusEnum.active,
                      workOrderId: 0,
                    },
                  },
                }
              );
            },
            title: "Duplicate",
          },
        ]
      : []),
    // Move
    ...(row.status === StatusEnum.active &&
    userInfo?.canMoveDeliveryOrders &&
    currentUser?.clients?.length > 1
      ? [
          {
            onClick: () => handleMove(row),
            title: "Move to another branch",
          },
        ]
      : []),
    { type: "divider" },
    // Delete
    ...(userInfo?.canDeleteDeliveryOrders
      ? [
          {
            className: "text-danger",
            onClick: () => {
              showConfirmation(
                "Confirmation",
                "Do you really want to delete this order?",
                [
                  {
                    color: ThemeColorsEnum.quaternary,
                    title: "Cancel",
                  },
                  {
                    color: ThemeColorsEnum.danger,
                    onClick: () => handleDelete(row),
                    title: "Delete",
                  },
                ]
              );
            },
            title: "Delete",
          },
        ]
      : []),
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: WorkOrderColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    // customer
    ...(branchSettings?.columnsVisibility?.showCustomer
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.customer,
              name: "customer",
              text: "Customer",
              sortable: true,
            },
            cell: (row) => row.customerDisplayName,
            dataTitle: "Customer",
            className: "w-fit",
          },
        ]
      : []),
    // notes
    ...(branchSettings?.columnsVisibility?.showNotes
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.notes,
              name: "notes",
              text: "Notes",
              sortable: true,
            },
            cell: (row) =>
              row.notes ? (
                <CircleButton
                  color={ThemeColorsEnum.secondary}
                  size={30}
                  // style={{ backgroundColor: "#eaeaea" }}
                  IconComponent={() => (
                    <ChatIcon
                      className="cur-pointer"
                      style={{ fontSize: 20 }}
                    />
                  )}
                  onClick={(event) => {
                    handleShowNotes(row, event);
                  }}
                />
              ) : null,
            dataTitle: "Notes",
            className: "nowrap",
          },
        ]
      : []),
    // products
    ...(branchSettings?.columnsVisibility?.showProducts
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.products,
              name: "products",
              text: "Products",
              sortable: true,
            },
            cell: (row) =>
              row?.productsListFormatted?.map((productInfo, index) => (
                <div key={productInfo + index}>{productInfo}</div>
              )),
            dataTitle: "Products",
            className: "nowrap",
          },
        ]
      : []),
    // created date
    ...(branchSettings?.columnsVisibility?.showCreatedDate
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.enteredOn,
              name: "createdDate",
              text: "Entered on",
              sortable: true,
            },
            cell: (row) => row.createdDateFormatted,
            dataTitle: "Entered on",
            className: "nowrap",
          },
        ]
      : []),
    // assignedToUserId
    ...(branchSettings?.columnsVisibility?.showAssignedTo
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.assignedTo,
              name: "assignedToUserId",
              text: "Driver",
              sortable: true,
            },
            cell: (row) =>
              row.assignedToUserId
                ? row.assignedToName
                : row.status !== StatusEnum.completed && (
                    <Badge
                      bg={ThemeColorsEnum.unassigned}
                      className="badge-unassigned cur-pointer"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleAssign(row, event);
                      }}
                    >
                      Assign
                    </Badge>
                  ),
            dataTitle: "Driver",
            className: "nowrap",
          },
        ]
      : []),
    // orderStatusId
    ...(branchSettings?.columnsVisibility?.showOrderStatus
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.status,
              name: "orderStatusId",
              text: "Status",
              sortable: true,
            },
            cell: (row) => (
              <Badge
                bg={null}
                className={Services.workOrders.getBadgeClassByOrderStatus(
                  row.orderStatusId
                )}
              >
                {row.orderStatus}
              </Badge>
            ),
            dataTitle: "Status",
            className: "nowrap",
          },
        ]
      : []),
    // dueDate
    ...(branchSettings?.columnsVisibility?.showDueDate
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.dueDate,
              name: "dueDate",
              text: "Due on",
              sortable: true,
            },
            cell: (row) => row.dueDateFormatted,
            dataTitle: "Due on",
            className: "nowrap",
          },
        ]
      : []),
    // completedDate
    ...(branchSettings?.columnsVisibility?.showCompletedDate
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.completedOn,
              name: "completedDate",
              text: "Completed on",
              sortable: true,
            },
            cell: (row) => row.completedDateFormatted,
            dataTitle: "Completed on",
            className: "nowrap",
          },
        ]
      : []),
    // completedByUserId
    ...(branchSettings?.columnsVisibility?.showCompletedBy
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.completedBy,
              name: "completedByUserId",
              text: "Completed by",
              sortable: true,
            },
            cell: (row) => row.completedByName,
            dataTitle: "Completed by",
            className: "nowrap",
          },
        ]
      : []),
    // locationName
    ...(branchSettings?.columnsVisibility?.showLocation
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.location,
              name: "locationName",
              text: "Location info",
              sortable: true,
            },
            cell: (row) => row.locationName,
            dataTitle: "Location info",
            className: "nowrap",
          },
        ]
      : []),
    // locationName
    ...(branchSettings?.columnsVisibility?.showConfirmation && directDrops
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.confirmation,
              name: "confirmation",
              text: "Confirmation info",
              sortable: true,
            },
            cell: (row) => row.confirmation,
            dataTitle: "Confirmation info",
            className: "nowrap",
          },
        ]
      : []),
    // tattleInfo
    ...(branchSettings?.columnsVisibility?.showTattleNotes
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.tattleNotes,
              name: "tattleNotes",
              text: "Tattle notes",
              sortable: true,
            },
            cell: (row) => row.tattleInfoJSON?.notes,
            dataTitle: "Tattle notes",
            className: "nowrap",
          },
        ]
      : []),
    // truckId
    ...(branchSettings?.columnsVisibility?.showTruck
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.truck,
              name: "truckId",
              text: "Unit",
              sortable: true,
            },
            cell: (row) => row.truckName,
            dataTitle: "Unit",
            className: "nowrap",
          },
        ]
      : []),
    // high priority
    ...(branchSettings?.columnsVisibility?.showHighPriority
      ? [
          {
            header: {
              id: WorkOrderColumnsEnum.isHighPriority,
              name: "isHighPriority",
              text: "High Priority",
              sortable: true,
            },
            cell: (row) => (row.isHighPriority ? "Yes" : ""),
            dataTitle: "High Priority",
            className: "nowrap text-center",
          },
        ]
      : []),
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.isHighPriority === true,
      classNames: [ThemeColorsEnum.highPrio],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due && !row.isHighPriority,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue&& !row.isHighPriority,
      classNames: [ThemeColorsEnum.overdue],
    },
    
  ];

  const defaultSortColumn = (completed) =>
    completed
      ? WorkOrderColumnsEnum.completedOn
      : branchSettings?.workOrdersSortColumnIndex ??
        WorkOrderColumnsEnum.dueDate;

  const defaultSortOrder = (completed) =>
    completed
      ? SortDirections.descending
      : branchSettings?.workOrdersSortDirection ?? SortDirections.ascending;

  return {
    columns,
    conditionalRowStyles,
    defaultSortColumn,
    defaultSortOrder,
  };
};

export default useWorkOrdersDataTable;
