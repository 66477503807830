import { LoadingContext, ModalContext } from "../context/contextProvider";
import { ScheduledOrderColumnsEnum, ThemeColorsEnum } from "../constants/enums";

import { AppRoutes } from "../constants/appRoutes";
import ChatIcon from "@mui/icons-material/Chat";
import CircleButton from "../components/buttons/CircleButton";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import { useContext } from "react";
import useCurrentUserInfo from "./useCurrentUserInfo";
import { useNavigate } from "react-router-dom";

const useSchedulesDataTable = (onDeleted = () => {}) => {
  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();

  const handleDelete = async (scheduledOrder) => {
    setLoading(true);
    const response = await Services.api.scheduledOrders.deleteOrder(scheduledOrder.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the schedule",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Schedule deleted", ThemeColorsEnum.success);
    onDeleted(scheduledOrder);
  };

  const handleShowNotes = (workOrder, event) => {
    event.stopPropagation();
    showConfirmation("Notes", workOrder.notes, [
      {
        color: ThemeColorsEnum.quaternary,
        onClick: () => {},
        title: "OK",
      },
    ]);
  };

  const getOrderDetails = (row) => {
    if (row.workOrder && !row.workOrder.isDirectDrop) {
      //Delivery order
      return {
		orderType: "Delivery Order",
        customerDisplayName: row.workOrder.customerDisplayName || 'N/A',
        customerLocationName: row.workOrder.customerLocationName || 'N/A',
        notes: row.workOrder.notes || '',
        products: row.workOrder.productsListFormatted || [],
        // Add other common fields here
      };
    }
    else if (row.workOrder && row.workOrder.isDirectDrop ) {
      return {
		orderType: "Direct Drop",
        customerDisplayName: row.workOrder.customerDisplayName || 'N/A',
        customerLocationName: row.workOrder.custtomerLocationName || 'N/A',
        notes: row.workOrder.notes || '',
        products: row.workOrder.productsListFormatted || [],
        // Add other common fields here
      };
    }
    else if (row.serviceOrder) {
      //Service order
      return {
		orderType: "Service Order",
        customerDisplayName: row.serviceOrder.customerDisplayName || 'N/A',
        customerLocationName: row.serviceOrder.customerLocationName || 'N/A',
        notes: row.serviceOrder.notes || '',
      };
    }
  };

  const menuItems = (row) => [
    // Edit
    {
      onClick: () =>
        navigate(AppRoutes.scheduledOrders_edit.replace(":id", row.id), {
          state: { 
			scheduledOrder: row,
		    order: row.workOrder || row.serviceOrder,	
			},
        }),
      title: "Edit",
    },
    // Duplicate
    ...(userInfo?.canCreateDeliveryOrders
      ? [
          {
            onClick: () => {
              navigate(AppRoutes.scheduledOrders_create, {
                state: {
                  scheduledOrder: {
                    ...row,
                    id: 0,
                    dueDate: null,
                    scheduleJSON: { ...row.scheduleJSON, startDate: null },
                  },
                  order: row.workOrder || row.serviceOrder,
                },
              });
            },
            title: "Duplicate",
          },
        ]
      : []),
    { type: "divider" },
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showConfirmation(
          "Confirmation",
          "Do you really want to delete this order?",
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "Cancel",
            },
            {
              color: ThemeColorsEnum.danger,
              onClick: () => handleDelete(row),
              title: "Delete",
            },
          ]
        );
      },
      title: "Delete",
    },
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: ScheduledOrderColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
	{
		header: {
			id: ScheduledOrderColumnsEnum.orderType,
			name: "orderType",
			text: "Type",
			},
		cell: (row) => getOrderDetails(row).orderType,
	},
    {
      header: {
        id: ScheduledOrderColumnsEnum.customer,
        name: "customer",
        text: "Customer",
        sortable: true,
      },
      cell: (row) => getOrderDetails(row).customerDisplayName,
      dataTitle: "Customer",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.customerLocation,
        name: "location",
        text: "Location",
        sortable: false,
      },
      cell: (row) => getOrderDetails(row).customerLocationName,
      dataTitle: "Location",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.notes,
        name: "notes",
        text: "Notes",
        sortable: true,
      },
      cell: (row) => {
        const notes = getOrderDetails(row).notes;
        return notes ? (
          <CircleButton
            color={ThemeColorsEnum.secondary}
            size={30}
            IconComponent={() => (
              <ChatIcon className="cur-pointer" style={{ fontSize: 20 }} />
            )}
            onClick={(event) => {
              handleShowNotes(getOrderDetails(row), event);
            }}
          />
        ) : null;
      },
      dataTitle: "Notes",
      className: "nowrap",
    },
    // {
    //   header: {
    //     id: ScheduledOrderColumnsEnum.notes,
    //     name: "notes",
    //     text: "Notes",
    //     sortable: true,
    //   },
    //   cell: (row) => row.notes,
    //   dataTitle: "Notes",
    //   className: "nowrap",
    // },
    {
      header: {
        id: ScheduledOrderColumnsEnum.products,
        name: "products",
        text: "Products",
        sortable: true,
      },
      cell: (row) => {
        const orderDetails = getOrderDetails(row);
        if (orderDetails.products && orderDetails.products.length > 0) {
          return orderDetails.products.map((product, index) => (
            <div key={index}>{product}</div>
          ));
        }
        return 'No products';
      },
      dataTitle: "Products",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.createOn,
        name: "createOn",
        text: "Create on",
        sortable: true,
      },
      cell: (row) =>
        Services.utility.dayNameFromIndex(row.scheduleJSON.createOn),
      dataTitle: "Create on",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.dueOn,
        name: "dueOn",
        text: "Due on",
        sortable: true,
      },
      cell: (row) => Services.utility.dayNameFromIndex(row.scheduleJSON.dueOn),
      dataTitle: "Due on",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.interval,
        name: "interval",
        text: "Repeat (weeks)",
        sortable: true,
      },
      cell: (row) => `${row.scheduleJSON.weeksInterval} week(s)`,
      dataTitle: "Repeat (weeks)",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledOrderColumnsEnum.startDate,
        name: "startDate",
        text: "Start Date",
        sortable: true,
      },
      cell: (row) =>
        Services.utility.formatFullDate(row.scheduleJSON.startDate),
      dataTitle: "Start Date",
      className: "nowrap",
    },
  ];

  return { columns };
};

export default useSchedulesDataTable;
