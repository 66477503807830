import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import ScheduledOrderFormPage from "./ScheduledOrderFormPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: EventRepeatIcon,
    title: "Scheduled Orders",
    to: AppRoutes.scheduledOrders,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const ScheduledOrderCreatePage = (props) => {
  const { state } = useLocation();
  const [scheduledOrder, setScheduledOrder] = useState();
  const [workOrderType, setWorkOrderType] = useState(state?.scheduledOrder.workOrderType || 0);

  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setScheduledOrder(
      state?.scheduledOrder ?? props.location?.state?.scheduledOrder
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!branchModules?.workOrders || !userInfo.canCreateDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <ScheduledOrderFormPage
	  initialWorkOrderType={workOrderType} //see navlink in sidebar
      breadcrumbs={breadcrumbs}
      title="Scheduled Orders - New"
      scheduledOrder={state?.scheduledOrder}
      order={state?.scheduledOrder.workOrder || state?.scheduledOrder.serviceOrder}
    />
  );
};

export default ScheduledOrderCreatePage;
