import { AuthContext, ModalContext } from "../context/contextProvider";
import {
  SortDirections,
  ThemeColorsEnum,
  WorkOrderColumnsEnum,
} from "../constants/enums";
import { useContext, useMemo } from "react";

import { Badge } from "react-bootstrap";
import ChatIcon from "@mui/icons-material/Chat";
import CircleButton from "../components/buttons/CircleButton";
import Services from "../services/services";

const useDispatchReportsDataTable = () => {
  const { branchSettings } = useContext(AuthContext);

  const { showConfirmation } = useContext(ModalContext);

  const handleShowNotes = (workOrder, event) => {
    event.stopPropagation();
    showConfirmation("Notes", workOrder.notes, [
      {
        color: ThemeColorsEnum.quaternary,
        onClick: () => {},
        title: "OK",
      },
    ]);
  };

  const columns = useMemo(
    () => [
      // customer
      ...(branchSettings?.columnsVisibility?.showCustomer
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.customer,
                name: "customer",
                text: "Customer",
                sortable: true,
              },
              cell: (row) => row.customerDisplayName,
              dataTitle: "Customer",
              className: "w-fit",
            },
          ]
        : []),
      // notes
      ...(branchSettings?.columnsVisibility?.showNotes
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.notes,
                name: "notes",
                text: "Notes",
                sortable: true,
              },
              cell: (row) =>
                row.notes ? (
                  <CircleButton
                    color={ThemeColorsEnum.secondary}
                    size={30}
                    // style={{ backgroundColor: "#eaeaea" }}
                    IconComponent={() => (
                      <ChatIcon
                        className="cur-pointer"
                        style={{ fontSize: 20 }}
                      />
                    )}
                    onClick={(event) => {
                      handleShowNotes(row, event);
                    }}
                  />
                ) : null,
              dataTitle: "Notes",
              className: "nowrap",
            },
          ]
        : []),
      // products
      ...(branchSettings?.columnsVisibility?.showProducts
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.products,
                name: "products",
                text: "Products",
                sortable: true,
              },
              cell: (row) =>
                row?.productsListFormatted?.map((productInfo, index) => (
                  <div key={productInfo + index}>{productInfo}</div>
                )),
              dataTitle: "Products",
              className: "nowrap",
            },
          ]
        : []),
      // created date
      ...(branchSettings?.columnsVisibility?.showCreatedDate
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.enteredOn,
                name: "createdDate",
                text: "Entered on",
                sortable: true,
              },
              cell: (row) => row.createdDateFormatted,
              dataTitle: "Entered on",
              className: "nowrap",
            },
          ]
        : []),
      // assignedToUserId
      ...(branchSettings?.columnsVisibility?.showAssignedTo
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.assignedTo,
                name: "assignedToUserId",
                text: "Driver",
                sortable: true,
              },
              cell: (row) => row.assignedToName ?? "Unassigned",
              dataTitle: "Driver",
              className: "nowrap",
            },
          ]
        : []),
      // orderStatusId
      ...(branchSettings?.columnsVisibility?.showOrderStatus
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.status,
                name: "orderStatusId",
                text: "Status",
                sortable: true,
              },
              cell: (row) => (
                <Badge
                  bg={null}
                  className={Services.workOrders.getBadgeClassByOrderStatus(
                    row.orderStatusId
                  )}
                >
                  {row.orderStatus}
                </Badge>
              ),
              dataTitle: "Status",
              className: "nowrap",
            },
          ]
        : []),
      // dueDate
      // ...(branchSettings?.columnsVisibility?.showDueDate
      //   ? [
      //       {
      //         header: {
      //           id: WorkOrderColumnsEnum.dueDate,
      //           name: "dueDate",
      //           text: "Due on",
      //           sortable: true,
      //         },
      //         cell: (row) => row.dueDateFormatted,
      //         dataTitle: "Due on",
      //         className: "nowrap",
      //       },
      //     ]
      //   : []),
      // completedDate
      ...(branchSettings?.columnsVisibility?.showCompletedDate
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.completedOn,
                name: "completedDate",
                text: "Completed on",
                sortable: true,
              },
              cell: (row) => row.completedDateFormatted,
              dataTitle: "Completed on",
              className: "nowrap",
            },
          ]
        : []),
      // completedByUserId
      ...(branchSettings?.columnsVisibility?.showCompletedBy
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.completedBy,
                name: "completedByUserId",
                text: "Completed by",
                sortable: true,
              },
              cell: (row) => row.completedByName,
              dataTitle: "Completed by",
              className: "nowrap",
            },
          ]
        : []),
      // locationName
      ...(branchSettings?.columnsVisibility?.showLocation
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.location,
                name: "locationName",
                text: "Location info",
                sortable: true,
              },
              cell: (row) => row.locationName,
              dataTitle: "Location info",
              className: "nowrap",
            },
          ]
        : []),
      // tattleInfo
      ...(branchSettings?.columnsVisibility?.showTattleNotes
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.tattleNotes,
                name: "tattleNotes",
                text: "Tattle notes",
                sortable: true,
              },
              cell: (row) => row.tattleInfoJSON?.notes,
              dataTitle: "Tattle notes",
              className: "nowrap",
            },
          ]
        : []),
      // truckId
      ...(branchSettings?.columnsVisibility?.showTruck
        ? [
            {
              header: {
                id: WorkOrderColumnsEnum.truck,
                name: "truckId",
                text: "Unit",
                sortable: true,
              },
              cell: (row) => row.truckName,
              dataTitle: "Unit",
              className: "nowrap",
            },
          ]
        : []),
    ],
    [branchSettings] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue,
      classNames: [ThemeColorsEnum.overdue],
    },
  ];

  const defaultSortColumn = (completed) =>
    completed
      ? WorkOrderColumnsEnum.completedOn
      : branchSettings?.workOrdersSortColumnIndex ??
        WorkOrderColumnsEnum.dueDate;

  const defaultSortOrder = (completed) =>
    completed
      ? SortDirections.descending
      : branchSettings?.workOrdersSortDirection ?? SortDirections.ascending;

  return { columns, conditionalRowStyles, defaultSortColumn, defaultSortOrder };
};

export default useDispatchReportsDataTable;
